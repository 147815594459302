import "../CSS/Buttons.css"
import ScaleText from "react-scale-text";
import {Link} from "react-router-dom";

export default {

    Redbutton(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtons " + props.className}>
            <ScaleText maxFontSize={16}>
                {props.text}
            </ScaleText>
        </button>
    },

    RedbuttonSend(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtonsSend " + props.className}>
            {props.text}
            <svg width="38" height="43" viewBox="0 0 38 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_148_163)">
                    <path d="M32.005 22.7928L14.5497 32.1879C14.3429 32.2984 14.0975 32.2495 13.9359 32.0656L13.8132 31.9258C13.6626 31.7541 13.6118 31.4992 13.6828 31.2704L15.7086 24.8386C16.0076 23.8908 16.7816 23.2443 17.6652 23.2044L22.4835 22.9594C22.773 22.9515 23.006 22.6861 23.0129 22.3564L23.013 21.6923C23.006 21.3626 22.773 21.0972 22.4836 21.0893L17.6653 20.8449C16.7817 20.8052 16.0078 20.1588 15.7089 19.2109L13.6837 12.7794C13.5914 12.5475 13.6308 12.2751 13.7834 12.0891L13.9062 11.9492C14.0653 11.7371 14.3293 11.6727 14.5507 11.7919L32.0051 21.1849C32.194 21.2844 32.3162 21.4983 32.3196 21.7354L32.3196 22.1898C32.3348 22.4456 32.2096 22.6856 32.005 22.7928Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_148_163">
                        <rect width="27.9583" height="27.9583" fill="white" transform="matrix(0.659777 0.751461 -0.659777 0.751461 18.9248 0.980835)"/>
                    </clipPath>
                </defs>
            </svg>
        </button>
    },

    RedbuttonSave(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtonsSend " + props.className}>
            {props.text}
            <svg style={{width: "30px", height: "30px"}} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_168_649)">
                    <path d="M25.5125 8.0125L21.9875 4.4875C21.5178 4.01495 20.8788 3.74947 20.2125 3.75H6.25C4.86929 3.75 3.75 4.86929 3.75 6.25V23.75C3.75 25.1307 4.86929 26.25 6.25 26.25H23.75C25.1307 26.25 26.25 25.1307 26.25 23.75V9.7875C26.2505 9.12119 25.985 8.48225 25.5125 8.0125ZM11.25 8.125V6.25H18.75V8.125C18.75 8.47018 18.4702 8.75 18.125 8.75H11.875C11.5298 8.75 11.25 8.47018 11.25 8.125ZM21.25 23.75H8.75V14.375C8.75 14.0298 9.02982 13.75 9.375 13.75H20.625C20.9702 13.75 21.25 14.0298 21.25 14.375V23.75ZM18.125 18.75H11.875C11.5298 18.75 11.25 19.0298 11.25 19.375V20.625C11.25 20.9702 11.5298 21.25 11.875 21.25H18.125C18.4702 21.25 18.75 20.9702 18.75 20.625V19.375C18.75 19.0298 18.4702 18.75 18.125 18.75Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_168_649">
                        <rect width="30" height="30" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </button>
    },

    TransparentbuttonGear(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtonsSend TransparentBt BTGear "  + props.className}>
            {props.text}
            <svg style={{left: 'unset', right: "10px"}} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.25 18.4193L25.25 17.5927C24.6437 17.0884 24.3101 16.3271 24.35 15.5386C24.35 15.3632 24.35 15.1753 24.35 15C24.35 14.8247 24.35 14.6368 24.35 14.4614C24.3101 13.6729 24.6437 12.9116 25.25 12.4073L26.25 11.5807C26.7245 11.1952 26.8513 10.5225 26.55 9.99002L25.1 7.48503C24.7926 6.96202 24.1553 6.73507 23.5875 6.94646L22.3375 7.39736C21.5998 7.6736 20.7753 7.58542 20.1125 7.15938C19.8186 6.95597 19.5094 6.7759 19.1875 6.62081C18.4841 6.25938 17.9928 5.58592 17.8625 4.80469L17.65 3.5522C17.5509 2.93966 17.0192 2.49212 16.4 2.50011H13.525C12.9057 2.49212 12.374 2.93966 12.275 3.5522L12.0625 4.80469C11.9431 5.57605 11.4717 6.24755 10.7875 6.62081C10.4656 6.7759 10.1563 6.95597 9.86248 7.15938C9.19961 7.58542 8.37515 7.6736 7.63748 7.39736L6.38748 6.94646C5.82691 6.74889 5.20502 6.97406 4.89998 7.48503L3.44998 9.99002C3.14863 10.5225 3.2755 11.1952 3.74998 11.5807L4.74998 12.4073C5.35629 12.9116 5.68982 13.6729 5.64998 14.4614C5.64998 14.6368 5.64998 14.8247 5.64998 15C5.64998 15.1753 5.64998 15.3632 5.64998 15.5386C5.68982 16.3271 5.35629 17.0884 4.74998 17.5927L3.74998 18.4193C3.2755 18.8048 3.14863 19.4775 3.44998 20.01L4.89998 22.515C5.20732 23.038 5.84467 23.2649 6.41248 23.0535L7.66248 22.6026C8.40015 22.3264 9.22461 22.4146 9.88748 22.8406C10.1813 23.044 10.4906 23.2241 10.8125 23.3792C11.5159 23.7406 12.0072 24.4141 12.1375 25.1953L12.35 26.4478C12.449 27.0603 12.9807 27.5079 13.6 27.4999H16.475C17.0942 27.5079 17.6259 27.0603 17.725 26.4478L17.9375 25.1953C18.0678 24.4141 18.5591 23.7406 19.2625 23.3792C19.5844 23.2241 19.8936 23.044 20.1875 22.8406C20.8503 22.4146 21.6748 22.3264 22.4125 22.6026L23.6625 23.0535C24.2082 23.2246 24.8001 23.0028 25.1 22.515L26.55 20.01C26.8513 19.4775 26.7245 18.8048 26.25 18.4193ZM15 18.7575C12.9289 18.7575 11.25 17.0752 11.25 15C11.25 12.9248 12.9289 11.2425 15 11.2425C17.071 11.2425 18.75 12.9248 18.75 15C18.75 15.9965 18.3549 16.9523 17.6516 17.6569C16.9484 18.3616 15.9945 18.7575 15 18.7575Z" />
            </svg>
        </button>
    },

    TransparentbuttonClose(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtonsSend TransparentClose BTGear " + props.className}>
            {props.text}
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 3.02143L26.9786 0L15 11.9786L3.02143 0L0 3.02143L11.9786 15L0 26.9786L3.02143 30L15 18.0214L26.9786 30L30 26.9786L18.0214 15L30 3.02143Z" />
            </svg>
        </button>
    },

    AcceptButton(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtons  SelectBt " + props.className}>
            {props.text}
        </button>
    },

    CancelButton(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtons  CancelSelectBt " + props.className}>
            {props.text}
        </button>
    },

    Transparentbutton(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtons  TransparentBt " + props.className}>
            {props.text}
        </button>
    },

    RedbuttonNext(props) {
        return <button style={props.style} onClick={props.onClick} className={"RedButtonsNext " + props.className}>
            {props.text}
            <svg width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.56543 13.9167V12.0833C0.56543 11.5771 0.930415 11.1667 1.38065 11.1667H27.7448L20.4893 3.02668C20.335 2.85456 20.2482 2.62026 20.2482 2.37585C20.2482 2.13143 20.335 1.89713 20.4893 1.72501L21.647 0.441681C21.8 0.268144 22.0084 0.170532 22.2258 0.170532C22.4431 0.170532 22.6515 0.268144 22.8046 0.441681L32.8154 11.68C33.0448 11.9376 33.1738 12.2871 33.1741 12.6517V13.3483C33.1704 13.7121 33.0419 14.0602 32.8154 14.32L22.8046 25.5583C22.6515 25.7319 22.4431 25.8295 22.2258 25.8295C22.0084 25.8295 21.8 25.7319 21.647 25.5583L20.4893 24.2567C20.336 24.0877 20.2496 23.8564 20.2496 23.615C20.2496 23.3736 20.336 23.1423 20.4893 22.9733L27.7448 14.8333H1.38065C0.930415 14.8333 0.56543 14.4229 0.56543 13.9167Z" fill="white"/>
            </svg>
        </button>
    },

    DarkGreenButton(props) {
        return <button style={props.style} onClick={props.onClick} className={"DarkGreenButtons"}>
            {props.text}
        </button>
    },

    DarkButton(props) {
        return <button style={props.style} onClick={props.onClick} className={"DarkButtons"}>
            {props.text}
        </button>
    },

    RedLink(props) {
        return <Link to={props.to} style={props.style} className={"RedButtons"}>
            {props.text}
        </Link>
    },

    DarkGreenLink(props) {
        return <Link to={props.to} style={props.style} className={"DarkGreenButtons"}>
            {props.text}
        </Link>
    },

    DarkLink(props) {
        return <Link to={props.to} style={props.style} className={"DarkButtons"}>
            {props.text}
        </Link>
    }

}