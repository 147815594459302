import React, { Component } from "react";
import "../CSS/Normalnput.css"

class InputSelect extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Load:false,
            Pop: false,
            itmes: [],
            SelectedId: 0
        }
        if (this.props.items !== undefined) this.state.itmes = this.props.items

        this.wrapper = React.createRef()
        this.HandleClick = this.HandleOutside.bind(this)
    }

    ShowViewPop() {
        if (!this.state.Pop) {
            this.state.Pop = true
            this.forceUpdate()
        } else {
            this.state.Pop = false
            this.forceUpdate()
        }
    }

    ClickOnItem(id) {
        this.state.Load = true
        this.state.SelectedId = id
        if (this.props.onChange !== undefined) {
            if (this.props.indextab === undefined) this.props.onChange(this.state.itmes[id])
            else this.props.onChange(this.props.indextab[id])
        }
        this.forceUpdate()

    }

    HandleOutside(event) {
        if (this.wrapper && !this.wrapper.current.contains(event.target)) {
            this.state.Pop = false
            this.forceUpdate()
        }
    }

    componentDidUpdate() {
        // this.state.SelectedId = 0
        // if (this.props.onChange !== undefined) {
        //     this.props.onChange(this.state.itmes[0])
        // }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.HandleClick)
        if (this.props.onChange !== undefined) {
            if (this.props.indextab === undefined) this.props.onChange(this.state.itmes[0])
            else this.props.onChange(this.props.indextab[0])
        }

    }

    componentWillUnmount() {
        document.removeEventListener("mouseleave", this.HandleClick)
    }

    ViewPop() {
        const tab = []
        for (let i = 0; i < this.state.itmes.length; i++)
            tab.push(
                <div onClick={this.ClickOnItem.bind(this, i)} className={ (this.state.SelectedId == i ? "InputNormal_select_box_item_selected " : "") + "InputNormal_select_box_item " + this.props.ItemClass}>
                    <a>{this.state.itmes[i]}</a>
                </div>
            )
        return <div className="InputNormal_select_box">{tab}</div>
    }

    render() {
        return (
            <div ref={this.wrapper} style={this.props.style} onClick={this.ShowViewPop.bind(this)} className={"InputNormal_Box  " + this.props.className}>
                <a>{this.state.itmes[this.state.SelectedId]}</a>
                <button className="InputNormal_select">
                    wbierz
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M12.14 18L11.86 18C11.5934 17.9992 11.3467 17.8588 11.21 17.63L5.99995 8.68C5.67995 8.14 5.67995 7.86 5.81995 7.62L5.95995 7.37C6.09301 7.14719 6.33056 7.00768 6.58995 7L17.41 7C17.6765 7.00081 17.9232 7.14121 18.06 7.37L18.2 7.62C18.34 7.86 18.34 8.14 17.93 8.84L12.79 17.63C12.6532 17.8588 12.4065 17.9992 12.14 18Z" fill="#23A287"/>
                        </g>
                    </svg>
                </button>
                {
                    this.state.Pop && this.ViewPop()
                }
            </div>
        )
    }
}

export default InputSelect