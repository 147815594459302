import serverPath from "./ServerPath"


export default {

    async Authorize(LoginInput, PasswordIntput, AutoLogin = false) {
        // console.log(serverPath() + "api/auth")
        try {
            const resp = await fetch(
                serverPath() + "api/auth",
                {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Login: LoginInput,
                        Password: PasswordIntput,
                        Auto: AutoLogin ? "1" : "0"
                    }),
                    credentials: 'include'
                }
            ).then(data => data.json()).catch(null)

            if (resp !== null) {
                if (resp["CODE"] === 'OK') {
                    return true
                } else {
                    return false
                }
            } else return false;

        } catch(e) {
            console.log(e)
            return false
        }
    },

    async CheckAuthorize() {
        try {
            const resp = await fetch(
                serverPath() + "api/authorize",
                {
                    method: "GET",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                    },
                    credentials: 'include'
                }
            ).then(data => data.json()).catch(null)
            // console.log(resp)
            if (resp !== null) {
                if (resp['CODE'] == 'OK') {
                    // console.log("jest")
                    return resp['DATA']
                } else return null
            } else return null
        } catch(e) {
            console.log(e,112)
            return null
        }
    }

}