import React from "react";
import "../../CSS/virtuallist.css";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import LoadingSvg from "../../Images/Loading.svg"

function VirtualTab(props) {
    const table = React.useRef()
    const scrollNode = React.useRef()
    const [tableData, setTableData] = React.useState([])
    const [Loading, SetLoading] = React.useState(false)
    const [state, setState] = React.useState({
        Counts: 0,
        Page: 0,
    })

    const MaxPages = React.useMemo(() => Math.ceil(state.Counts / 25), [state.Counts])

    const SetPage = React.useCallback((pageNumber) => {
        setState((data) => ({...data, Page: pageNumber}))
    }, [])

    const UpPage = React.useCallback((Up) => {
        if ((!Up && state.Page > 0) || (Up && state.Page < MaxPages - 1))
            setState((data) => ({...data, Page: Up ? data.Page + 1 : data.Page - 1}))
    }, [tableData, state])

    const RefreshPage = React.useCallback(() => {
        GetCountsRow()
        LoadData().then(() => {
            if (scrollNode.current)
                scrollNode.current.scrollTop = 0
        })
    }, [])

    React.useEffect(() => {
        GetCountsRow()
    },[])

    React.useEffect(() => {
        LoadData().then(() => {
            if (scrollNode.current)
                scrollNode.current.scrollTop = 0
        })
    }, [state.Page])

    async function LoadData() {
        SetLoading(true)
        const resp = await fetch(
            props.DataPath(state.Page),
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).catch(_ => null).then(out => out.json())
        if (resp != null) {
            setTableData(resp)
            SetLoading(false)
        }
    }

    async function GetCountsRow() {
        const resp = await fetch(
            props.CuntPath(),
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).catch(_ => null).then(out => out.text())
        if (resp != null) {
            setState((data) => ({...data, Counts: parseInt(resp)}))
        }
        // this.state.Loading.svg = false
    }

    return (

        <>
            <div
                ref={table}
                style={props.style}
                className="VL_wrapper"
            >
                <SimpleBar
                    style={{
                        width: "100%",
                        height: "100%",
                        // overflow:"auto"
                    }}
                    scrollableNodeProps={{ref: scrollNode}}
                >
                    <table
                        className="VL_wrapper_table"
                    >
                        <colgroup>
                            {
                                props.Headers.map(
                                    item => <col style={{width: item.width != undefined ? item.width: ""}}></col>
                                )
                            }
                        </colgroup>
                        <thead
                            style={{
                                transition: ".2s",
                                position: "sticky",
                                top: "0",
                                zIndex: "3"
                            }}
                        >
                        <tr>
                            {
                                props.Headers.map(
                                    item => <th>
                                        <a>
                                            {
                                                item.Name
                                            }
                                            {
                                                (item.SortValue != undefined && item.SortValue.length > 0) &&
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_95_457)">
                                                        <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_95_457">
                                                            <rect width="24" height="24" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            }
                                        </a>
                                    </th>
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.length === 0 && <tr><td colSpan={props.Headers.length.toString()}><h2 className="Vl_nodata_prompt">Brak dokumentów</h2></td></tr>}
                        {
                            tableData.length > 0 && tableData.map(
                                (item, index) => {
                                    return props.RenderRow(item, index, RefreshPage)
                                }
                            )
                        }
                        </tbody>
                    </table>
                </SimpleBar>
                {
                    Loading &&
                    <div style={{top: `0`, height: "100%"}} className="VL_wrapper_table_loading">
                        <img src={LoadingSvg}/>
                    </div>
                }
            </div>
            {
                state.Counts > 25 &&
                <div className="VL_wrapper_table_pagination">
                    <button
                        onClick={() => UpPage(false)}
                    >
                        <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 477.175 477.175">
                            <g>
                                <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
                                c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
                                "/>
                            </g>
                        </svg>
                    </button>
                    <div>
                        {
                            state.Page > 0 &&
                            <a onClick={() => SetPage(0)}>1</a>
                        }
                        {state.Page >= 2 && <a className="VL_wrapper_table_pagination_nonselected">...</a>}
                        {
                            Array(MaxPages).fill(1).map(
                                (_, index) => {
                                    if (index >= state.Page && index <= state.Page + 3)
                                        return <a
                                            className={state.Page === index ? "VL_wrapper_table_pagination_selected": ""}
                                            onClick={() => SetPage(index)}
                                        >{index + 1}</a>

                                }
                            )
                        }

                        {
                            MaxPages > 4 && <a className="VL_wrapper_table_pagination_nonselected">...</a>
                        }
                    </div>
                    <button
                        onClick={() => UpPage(true)}
                    >
                        <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 477.175 477.175">
                            <g>
                                <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
                                c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
                                "/>
                            </g>
                        </svg>
                    </button>
                </div>
            }
        </>
    )

}

export default VirtualTab;