import React, { Component } from "react";
import Buttons from "../../Inputs/Buttons";
import Calendar from "../../Inputs/Calendar";
import Chexbox from "../../Inputs/Chexbox";
import DocFIles from "../../Inputs/DocFiles";
import InputNormal from "../../Inputs/InputNormal";
import InputSelect from "../../Inputs/InputSelec";
import serverPath from "../../Utilis/ServerPath";
import "../../CSS/Responsive/r_dash.css"
import TextNormal from "../../Inputs/TextNormal";
import EndCalendar from "../../Inputs/EndTime";
import MarbaApi from "../../Utilis/MarbaApi";

class Dashboard extends Component {

    constructor(props) {
        super()
        this.state = {
            Step: 1,
            Loading: false,
            Error: false,
            ErrorText: '',
            LoadingVal: 0,
            Output: {
                InvoiceType : 0,
                Duplicate: "0",
                Correction: "0",
                NumberInovice: "",
                GrossAmountInovice: "",
                CurrencyInovice: "PLN",
                CommentsInovice: "",
                UserBankID: 0,
                ExhibitionDate: "",
                NotionDate : '',
                FilesAdded : ""
            },
            Inputs: {
                InvoiceType1: React.createRef(),
                InvoiceType2: React.createRef(),
                InvoiceType3: React.createRef(),
                InvoiceType4: React.createRef(),
                InvoiceType5: React.createRef(),
                DocumentType1: React.createRef(),
                DocumentType2: React.createRef(),
                fileInput: React.createRef(),
            },
            Errr: {
                Number: React.createRef(),
                GrossNumber: React.createRef(),
                EndDate: React.createRef(),
                SetDate: React.createRef(),
            },
            UserBankList: [],
            UserBankListID: [],
            UserBank: []
        }

        this.ErrorTables = {
            "0" : <>Wystapił bład ogólny (nr 0)<br></br>Sróbuj ponownie poźniej!</>,
            "8" : <>Brak kontrachenta w bazie danych<br></br>Sróbuj ponownie poźniej!</>,
            "7" : <>Brak rodzaju obiegu<br></br>Sróbuj ponownie poźniej!</>,
            "6" : <>Brak identyfikatora waluty<br></br>Sróbuj ponownie poźniej!</>,
            "9" : <>Zamknięty obieg dokumentów<br></br>Sróbuj ponownie poźniej!</>,
            "5" : <>Numer faktury juz istnieje w obiegu<br></br>Sróbuj ponownie poźniej!</>,
        }

    }

    componentDidMount() {
        // this.GetUserBankAccount()
        // setInterval(() => {
        //     if (this.state.LoadingVal >= 100) return
        //     this.state.LoadingVal += 1
        //     this.forceUpdate()
        // }, 100)
    }

    async SendInvoice() {
        this.state.LoadingVal = 10
        this.forceUpdate()

        const state = await MarbaApi.sendMarba(this.state.Output, this.props.UserData, this.state.Inputs.fileInput.current.state.files)
        // alert(state)
        if (state !== true) {
            this.state.Error = true
            // if (this.ErrorTables[state] != undefined) this.state.ErrorText = this.ErrorTables[state]
            // else this.state.ErrorText = `Bład ${state}`
            // this.state.Loading.svg = false
            // this.state.LoadingVal = 0
            // this.forceUpdate()
            // return
        }

        this.state.LoadingVal = 30
        this.forceUpdate()

        let out = await this.state.Inputs.fileInput.current.SendFiles(serverPath() + "api/files/upload")
        out.forEach(e => {
            this.state.Output.FilesAdded += e.ID + ";"
        })
        this.state.LoadingVal = 60
        this.forceUpdate()

        if (!this.state.Error)
            await fetch(
                serverPath() + "api/invoice/add",
                {
                    method: "POST",
                    headers: {
                        'Access-Control-Allow-Origin':'*',
                    },
                    credentials: 'include',
                    body: JSON.stringify(this.state.Output)
                }
            ).then(data => data.json()).catch(null)
        else await MarbaApi.sendQueueMarba(this.state.Output, this.props.UserData, state)
        this.state.LoadingVal = 100

        this.forceUpdate()
    }

    async GetUserBankAccount() {
        const resp = await fetch(
            serverPath() + "api/profile/bank",
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include'
            }
        ).then(data => data.json()).catch(null)

        if (resp !== null) this.state.UserBank = resp

        this.state.UserBank.forEach( e => {
            this.state.UserBankList.push(e.BankNumber)
            this.state.UserBankListID.push(e.ID)
        })

        this.forceUpdate()
    }

    StepNext() {
        // console.log(this.state.Output)

        const EmptyOnAll = () => {
            if (this.state.Output.NumberInovice.trim() == "") this.state.Errr.Number.current.SetError("Pole nie może być puste!")
            if (this.state.Output.GrossAmountInovice.trim() == "") this.state.Errr.GrossNumber.current.SetError("Pole nie może być puste!")
            else {
                if (isNaN(this.state.Output.GrossAmountInovice.trim()))
                    this.state.Errr.GrossNumber.current.SetError("Niepoprawna wartość!")
            }
            if (this.state.Output.ExhibitionDate.trim() == "") this.state.Errr.EndDate.current.input.current.SetError("Pole nie może być puste!")
            if (this.state.Output.NotionDate.trim() == "") this.state.Errr.SetDate.current.SetError("Pole nie może być puste!")
            // this.state.Errr.EndDate = "Pole nie może być puste!"
            this.forceUpdate()
        }


        // EmptyOnAll()
        if (this.state.Step === 1) {

            if (this.state.Output.InvoiceType != 0) {
                this.state.Step += 1
                this.forceUpdate()
            }

        } else if (this.state.Step == 2) {
            if (
                this.state.Output.ExhibitionDate.trim() !== "" &&
                this.state.Output.NotionDate.trim() !== "" &&
                this.state.Output.NumberInovice.trim() !== "" &&
                this.state.Output.GrossAmountInovice.trim() !== ""
            ) {
                if (isNaN(this.state.Output.GrossAmountInovice.trim())) {
                    this.state.Errr.GrossNumber.current.SetError("Niepoprawna wartość!")
                    return
                }
                if (this.state.Inputs.fileInput.current.state.files.length == 0) {
                    this.state.Error = true
                    this.state.ErrorText = "brak załączonych plików"
                    this.forceUpdate()
                    return
                }
                this.state.Loading = true
                this.forceUpdate()
                this.SendInvoice().then(
                    () => {
                        setTimeout(()=> {
                            if (this.state.LoadingVal > 30) {
                                this.state.Step += 1
                                this.state.Loading = false
                                this.state.LoadingVal = 0
                                this.state.Output = {
                                    InvoiceType : 0,
                                    Duplicate: "0",
                                    Correction: "0",
                                    NumberInovice: "",
                                    GrossAmountInovice: "",
                                    CurrencyInovice: "PLN",
                                    CommentsInovice: "",
                                    UserBankID: 0,
                                    ExhibitionDate: "",
                                    NotionDate : '',
                                    FilesAdded : ""
                                }
                                this.forceUpdate()
                            }
                        }, 5000)

                    }
                )
            } else EmptyOnAll()

        }

    }

    ResetForm() {
        this.state.Step = 1
        this.forceUpdate()
    }

    StepForm() {
        const CheckboxBtref = React.forwardRef((props, ref) => {
            return <Chexbox.CheckboxBt {...props} innerref={ref}></Chexbox.CheckboxBt>
        })


        if (this.state.Step === 1)  {
            return (
                <div style={{height: "600px", overflow: "unset"}} className="DashBoardView_workform" st>
                    <h2>Krok 1</h2>
                    <h1>Wybierz ścieżka dokumentu</h1>
                    <div className="DashBoardView_workform_select">
                        <div style={{marginTop: "50px"}}>
                            <div>
                                <CheckboxBtref checked={this.state.Output.InvoiceType == "10017" ? true : false} ref={this.state.Inputs.InvoiceType1} onChange={e => {
                                    if (e.target.checked) this.state.Output.InvoiceType = "10017"
                                }} onClick={e => {
                                    this.state.Inputs.InvoiceType2.current.checked = false
                                    this.state.Inputs.InvoiceType3.current.checked = false
                                    this.state.Inputs.InvoiceType4.current.checked = false
                                    this.state.Inputs.InvoiceType5.current.checked = false
                                }} style={{color: "#23A287"}} text={<b>Faktury kosztowe - Logistyka</b>}/>
                                <a>Transport, wykrojniki, testy laboratoryjne, usługi.</a>
                            </div>
                            <div style={{marginTop: "50px"}}>
                                <CheckboxBtref checked={this.state.Output.InvoiceType == "10006" ? true : false} ref={this.state.Inputs.InvoiceType2} onChange={e => {
                                    if (e.target.checked) this.state.Output.InvoiceType = "10006"
                                }} onClick={e=> {
                                    this.state.Inputs.InvoiceType1.current.checked = false
                                    this.state.Inputs.InvoiceType3.current.checked = false
                                    this.state.Inputs.InvoiceType4.current.checked = false
                                    this.state.Inputs.InvoiceType5.current.checked = false
                                }} style={{color: "#23A287"}} text={<b>Faktury magazynowe</b>}/>
                                <a>Surowce, komponenty, zapachy, opakowania oraz palety.</a>
                            </div>
                            <div style={{marginTop: "50px"}}>
                                <CheckboxBtref checked={this.state.Output.InvoiceType == "10011" ? true : false} ref={this.state.Inputs.InvoiceType3} onChange={e => {
                                    if (e.target.checked) this.state.Output.InvoiceType = "10011"
                                }} onClick={e=> {
                                    this.state.Inputs.InvoiceType1.current.checked = false
                                    this.state.Inputs.InvoiceType2.current.checked = false
                                    this.state.Inputs.InvoiceType4.current.checked = false
                                    this.state.Inputs.InvoiceType5.current.checked = false
                                }} style={{color: "#23A287"}} text={<b>Faktury kosztowe - Dział utrzymania ruchu</b>}/>
                                <a>Maszyny i części zamienne, elementy eksploatacyjne, narzędzia i przyrządy potrzebne do napraw.</a>
                            </div>
                            <div style={{marginTop: "50px"}}>
                                <CheckboxBtref checked={this.state.Output.InvoiceType == "10070" ? true : false} ref={this.state.Inputs.InvoiceType4} onChange={e => {
                                    if (e.target.checked) this.state.Output.InvoiceType = "10070"
                                }} onClick={e=> {
                                    this.state.Inputs.InvoiceType1.current.checked = false
                                    this.state.Inputs.InvoiceType2.current.checked = false
                                    this.state.Inputs.InvoiceType3.current.checked = false
                                    this.state.Inputs.InvoiceType5.current.checked = false
                                }} style={{color: "#23A287"}} text={<b>Faktury kosztowe – BHP</b>}/>
                                <a>Szkolenia pracowników, usługi serwisowe, przeglądy techniczne.</a>
                            </div>
                            <div style={{marginTop: "50px"}}>
                                <CheckboxBtref checked={this.state.Output.InvoiceType == "10036" ? true : false} ref={this.state.Inputs.InvoiceType5} onChange={e => {
                                    if (e.target.checked) this.state.Output.InvoiceType = "10036"
                                }} onClick={e=> {
                                    this.state.Inputs.InvoiceType1.current.checked = false
                                    this.state.Inputs.InvoiceType2.current.checked = false
                                    this.state.Inputs.InvoiceType3.current.checked = false
                                    this.state.Inputs.InvoiceType4.current.checked = false
                                }} style={{color: "#23A287"}} text={<b>Faktury magazynowe – BHP</b>}/>
                                <a>Odzież ochronna i środki w ochrony osobistej.</a>
                            </div>
                        </div>
                    </div>
                    <Buttons.RedbuttonNext onClick={() => {this.StepNext()}} className={"buttonnex"} text={"DALEJ"}/>
                </div>
            )
        }
        else if (this.state.Step === 2) {
            return (
                <div  className="DashBoardView_workform">
                    <h2>Krok 2</h2>
                    <h1>Formularz dokumentu</h1>
                    <a onClick={() => {window.location.reload()}} className={'DashBoardView_workformCloseBt'}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 3.02143L26.9786 0L15 11.9786L3.02143 0L0 3.02143L11.9786 15L0 26.9786L3.02143 30L15 18.0214L26.9786 30L30 26.9786L18.0214 15L30 3.02143Z" fill="#23A287"/>
                        </svg>
                        zamknij
                    </a>
                    <div className="DashBoardView_workform_select">
                        <div className="DashBoardView_workform_select_form">
                            <InputNormal className={"Huge_input"} onChange={e => this.state.Output.NumberInovice = e} style={{marginTop: "44px"}} ref={this.state.Errr.Number} text={"numer faktury"}/>
                            <InputNormal
                                onBlur={e => {
                                    if (!isNaN(this.state.Output.GrossAmountInovice)) {
                                        this.state.Output.GrossAmountInovice = parseFloat(this.state.Output.GrossAmountInovice).toFixed(2).toString()
                                        if (this.state.Errr.GrossNumber.current !== undefined) {
                                            this.state.Errr.GrossNumber.current.TextChange(parseFloat(this.state.Output.GrossAmountInovice).toFixed(2).toString())
                                            if (this.state.Errr.GrossNumber.current.state.input.current !== undefined)
                                                this.state.Errr.GrossNumber.current.state.input.current.value = parseFloat(this.state.Output.GrossAmountInovice).toFixed(2).toString()
                                            this.forceUpdate()
                                        }
                                    } else {
                                        if (this.state.Errr.GrossNumber.current !== undefined) this.state.Errr.GrossNumber.current.SetError("Niepoprawna wartość!")
                                    }
                                }}
                                onChange={e => {
                                    this.state.Output.GrossAmountInovice = e.replaceAll(",", ".");
                                    if (e.search(",") > 0 && this.state.Errr.GrossNumber.current !== undefined) {
                                        this.state.Errr.GrossNumber.current.TextChange(e.replaceAll(",", "."))
                                        if (this.state.Errr.GrossNumber.current.state.input.current !== undefined)
                                            this.state.Errr.GrossNumber.current.state.input.current.value = e.replaceAll(",", ".")
                                        this.forceUpdate()
                                    }
                                    // !isNaN(e.replaceAll(",", ".")) ? parseFloat(e.replaceAll(",", ".")).toFixed(2).toString() : e.replaceAll(",", ".")
                                }} style={{marginTop: "27px",marginRight: "50px", width: '250px', display: "inline-block"}} ref={this.state.Errr.GrossNumber} text={"kwota brutto"}/>
                            <InputSelect onChange={e => this.state.Output.CurrencyInovice = e} items={["PLN", "EUR", "USD"]} style={{marginTop: "27px", width: '250px', display: "inline-block"}} text={"PLN"} />
                            <br></br>
                            <Calendar ref={this.state.Errr.SetDate} onChange={e => {
                                this.state.Output.NotionDate =  e.getFullYear() + '-' + ((e.getMonth()+1).toString().length == 1 ? "0" + (e.getMonth()+1).toString() : (e.getMonth()+1).toString()) + "-" + (e.getDate().toString().length == 1 ? "0" + e.getDate().toString() : e.getDate().toString())
                            }} style={{marginTop: "27px",marginRight: "50px", width: '250px', display: "inline-block"}} text={"data wystawienia"}/>
                            {/* <InputNormal className={"Huge_input"} onChange={e => this.state.Output.ExhibitionDate = e} style={{marginTop: "27px", width: '250px', display: "inline-block"}} text={"termin płatności"}/> */}
                            <EndCalendar
                                ref={this.state.Errr.EndDate}
                                onChange={e => {
                                    this.state.Output.ExhibitionDate =  e.getFullYear() + '-' + ((e.getMonth()+1).toString().length == 1 ? "0" + (e.getMonth()+1).toString() : (e.getMonth()+1).toString()) + "-" + (e.getDate().toString().length == 1 ? "0" + e.getDate().toString() : e.getDate().toString())
                                }}
                                className={"Huge_input"} style={{marginTop: "0", width: '250px', display: "inline-block", position: "relative", marginBottom: "27px"}}/>
                            {/* <InputSelect className={"Huge_input"} onChange={e => this.state.Output.UserBankID = e} items={this.state.UserBankList} indextab={this.state.UserBankListID} style={{marginTop: "27px"}} text={"rachunek do przelewu"}/> */}
                            {/* <TextNormal className={"Huge_input"} onChange={e => this.state.Output.CommentsInovice = e}  style={{marginTop: "27px", marginBottom: "40px"}} text={"uwagi"}/> */}
                            <br/>
                            <CheckboxBtref checked={this.state.Output.Correction == "1"} ref={this.state.Inputs.DocumentType1} onChange={
                                e => {
                                    if (e.target.checked) this.state.Output.Correction = "1"
                                    else this.state.Output.Correction = "0"
                                }
                            } onClick={()=> {
                                // this.state.Inputs.DocumentType2.current.checked = false
                            }} style={{color: "#23A287", display: "initial", marginRight: "50px", height: "fit-content"}} text={<b>Korekta</b>}/>
                            <CheckboxBtref checked={this.state.Output.Duplicate == "1"} ref={this.state.Inputs.DocumentType2} onChange={
                                e => {
                                    if (e.target.checked) this.state.Output.Duplicate = "1"
                                    else this.state.Output.Duplicate = "0"
                                    // e.target.checked = true
                                }
                            } onClick={()=> {
                                // this.state.Inputs.DocumentType1.current.checked = false
                            }} style={{color: "#23A287", display: "inline-block"}} text={<b>Duplikat</b>}/>
                        </div>

                        <DocFIles ref={this.state.Inputs.fileInput}/>
                    </div>
                    <Buttons.RedbuttonSend onClick={() => {this.StepNext()}} className={"buttonsend"} text={"WYŚLIJ"}/>
                </div>
            )
        }
        else if (this.state.Step === 3) {
            return (
                <div style={{height: "420px", overflow: 'unset'}} className="DashBoardView_workform">
                    {/* <h2>Krok 2</h2> */}
                    {/* <h1>Formularz dokumentu</h1> */}
                    <div className="DashBoardView_workform_final">
                        <h1>DZIEKUJEMY</h1>
                        <b>Krok 3 - Dokument wysłany</b>
                        <Buttons.Redbutton onClick={() => {this.ResetForm()}} style={{left: "50%", transform: "translate(-50%, 0)"}} text={"DODAJ NOWY"}/>
                    </div>
                    {/* <Buttons.RedbuttonSend onClick={() => {this.StepNext()}} style={{float: 'right', right: "200px", top: "03px"}} text={"WYŚLIJ"}/> */}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="DashBoardView_main">
                {/* {
                    this.state.Error && <div className="DashBoardView_main_loadingPop_back">
                        <div className="DashBoardView_main_loadingPop_pop">
                            <a>Wystąpił błąd</a>
                            <p
                                style={{
                                    width: "100%",
                                    color: "white",
                                    textAlign: "center",
                                    marginTop: "30px",
                                    marginBottom: "20px"
                                }}
                            >
                                {this.state.ErrorText}
                            </p>
                            <Buttons.Redbutton
                                style={{
                                    position: "relative",
                                    left: "50%",
                                    transform: "translate(-50%, 0)"
                                }}
                                text={"OK"}
                                onClick={
                                    () => {this.state.Error = false;this.forceUpdate()}
                                }
                            />
                        </div>
                    </div>
                } */}
                {
                    this.state.Loading && <div className="DashBoardView_main_loadingPop_back">
                        <div className="DashBoardView_main_loadingPop_pop">
                            <a>To może chwilę potrwać</a>
                            <div className="DashBoardView_main_loadingPop_bar">
                                <div style={{left: ( -100  + this.state.LoadingVal).toString() + "%"}}><div></div></div>
                                <a style={{ zIndex: "3", background: `linear-gradient(240deg, #23A287 ${this.state.LoadingVal > 48 ? (70 - this.state.LoadingVal) : "100"}%, rgba(255,255,255,1) ${this.state.LoadingVal > 44 ? (100 -this.state.LoadingVal) : "0"}%)`,backgroundClip: "text",WebkitTextFillColor: "transparent"}}>{this.state.LoadingVal}%</a>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.StepForm()
                }
            </div>
        )
    }

}

export default Dashboard;