import serverPath from "./ServerPath"

let CurrencyTab = {
    "PLN": 1,
    "EUR": 10002,
    "USD": 10001,
}

const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export default {

    async sendMarba(DmsData, UsrData, files=null) {

        const resp =  await fetch(
            serverPath() + "api/proxy/SaveDMS",
            {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type':'application/json',
                    // 'Authorization': 'Basic ' + btoa("stream:Str3@m1@#") c3RyZWFtOlN0cjNAbTFAIw==
                },
                credentials: 'include',
                body: JSON.stringify({
                    "contractorModel": {
                        "nip": UsrData.NIP,
                        "number": 0,
                        "shortName": "",
                    },
                    "currency": CurrencyTab[DmsData.CurrencyInovice],
                    "dmsKind": parseInt(DmsData.InvoiceType),
                    "documentDate": DmsData.NotionDate,
                    "documentNumber": DmsData.NumberInovice,
                    "grossAmount": parseFloat(DmsData.GrossAmountInovice),
                    "paymentTerm": DmsData.ExhibitionDate,
                    "duplicate": parseInt(DmsData.Duplicate),
                    "correction": parseInt(DmsData.Correction)
                })
            })
            .then(
                resp => resp.text()
            )
            .catch(null)

        if (resp !== null) {
            if (isJson(resp) && isNaN(resp)) {
                await this.sendMarbaFiles(JSON.parse(resp), files)
                return true
            } else return resp
        }

        return 0
    },

    async sendQueueMarba(DmsData, UsrData, errorType) {

        await fetch(
            serverPath() + "api/queue/invoice/add",
            {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type':'application/json',
                    // 'Authorization': 'Basic ' + btoa("stream:Str3@m1@#") c3RyZWFtOlN0cjNAbTFAIw==
                },
                credentials: 'include',
                body: JSON.stringify({
                    ...DmsData,
                    Data: JSON.stringify({
                        "contractorModel": {
                            "nip": UsrData.NIP,
                            "number": 0,
                            "shortName": "",
                        },
                        "currency": CurrencyTab[DmsData.CurrencyInovice],
                        "dmsKind": parseInt(DmsData.InvoiceType),
                        "documentDate": DmsData.NotionDate,
                        "documentNumber": DmsData.NumberInovice,
                        "grossAmount": parseFloat(DmsData.GrossAmountInovice),
                        "paymentTerm": DmsData.ExhibitionDate,
                        "duplicate": parseInt(DmsData.Duplicate),
                        "correction": parseInt(DmsData.Correction)
                    }),
                    ErrorType: errorType
                })
            })
        // .then(
        //     resp => resp.text()
        // )
        //     .catch(null)

        // if (resp !== null) {
        //     // if (isJson(resp) && isNaN(resp)) {
        //     //     // await this.sendMarbaFiles(JSON.parse(resp), files)
        //     //     return true
        //     // } else return resp
        // }

        // return 0
    },

    async sendMarbaFiles(Sendata, formDatas) {

        for(let i = 0; i < formDatas.length; i++) {
            const form = new FormData()
            form.append("file", formDatas[i])
            await fetch(
                serverPath() + `api/proxy/uploadAttachment?IDENT=${Sendata.IDENT}&ID_SEKDOK=${Sendata.result[0]}`, {
                    method:"POST",
                    // mode: 'no-cors',
                    headers: {
                        'Access-Control-Allow-Origin':'*'
                        // 'Content-Type': 'multipart/form-data'
                    },
                    body: form,
                    credentials: 'include'
                })
        }

    }

}