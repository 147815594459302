const path = window.location.origin + '/'

// const path = 'http://www.wmp.com:9082/'

export default function serverPath() {
    if (window.localStorage.getItem("RPath") !== undefined) {
        return path
    } else {
        return window.localStorage.getItem("RPath")
    }
}