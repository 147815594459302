import {Component} from "react"
import "../../CSS/Views/mainview.css"
import InputSearch from "../../Inputs/Search"
import serverPath from "../../Utilis/ServerPath"
import ScaleText from "react-scale-text";
import ShowConfirmation from "../../Utilis/SendConfirm";
import {INVOICE_TYPE} from "../../Utilis/invoicesType";

class SendedDocuments extends Component {

    constructor(props) {
        super(props)

        this.state = {
            Query: "",
            filtes: "",
            Search: false,
            Page: 0,
            CanLoad: false,
            binded: false,
            Counts: {
                adds: 0,
                last: 0
            },
            Items: [

            ],
            SortType: [0,0,0,0,0]
        }
        // alert(JSON.stringify(this.props))
        this.loadfunc = this.LoadModrFunc.bind(this)
    }

    componentDidMount() {
        this.GetCount()
        this.GetInvoices()
        if (this.props.scroll !== undefined && this.props.scroll.current !== null) {
            this.props.scroll.current.addEventListener('scroll', this.loadfunc)
            this.state.binded = true
        }
    }

    componentWillUnmount() {
        if (this.props.scroll !== undefined && this.props.scroll.current !== null) {
            this.props.scroll.current.removeEventListener('scroll', this.loadfunc)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.binded && this.props.scroll.current !== null) {
            this.props.scroll.current.addEventListener('scroll', this.loadfunc)
            this.state.binded = true
        }
    }

    LoadModrFunc(e) {
        if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight+30)
        {
            if (this.state.CanLoad) {
                this.state.Page += 1
                if (!this.state.Search) this.GetInvoices()
                else this.StartSearch(this.state.Query, this.state.filtes)
            }
        }
    }

    async GetCount() {
        await fetch(
            serverPath() + "api/invoice/usercount",
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify(this.state.Output)
            }
        ).then(data => data.json()).then(data => {
            this.state.Counts = data
            this.forceUpdate()
        })
    }

    async GetInvoices() {
        await fetch(
            serverPath() + "api/invoice/useradd?page=" + this.state.Page.toString(),
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify(this.state.Output)
            }
        ).then(data => data.json()).then(data => {
            if (Object.entries(data).length >= 25) this.state.CanLoad = true
            else this.state.CanLoad = false
            this.state.Items = this.state.Items.concat(data)
            this.forceUpdate()
        })
    }

    async StartSearch(val, filtres) {
        if (val.trim() == "") {
            this.state.Items = []
            this.state.Page = 0
            this.state.CanLoad = false
            this.GetInvoices()
            return
        }
        if (val !== this.state.Query || filtres !== this.state.filtes) {
            this.state.Query = val
            this.state.filtes = filtres
            this.state.Search = false
        } else {
            if (this.state.Page === 0) return
        }
        if (!this.state.Search) {this.state.Page = 0
            this.state.CanLoad = false}
        await fetch(
            serverPath() + `api/invoice/useradd/search?query=${encodeURIComponent(val)}&filtres=${filtres}&page=${this.state.Page}`,
            {
                method: "GET",
                headers: {
                    'Access-Control-Allow-Origin':'*',
                },
                credentials: 'include',
                body: JSON.stringify(this.state.Output)
            }
        ).then(data => data.json()).then(data => {
            if (!this.state.Search) this.state.Items = data
            else this.state.Items = this.state.Items.concat(data)
            this.state.Search = true
            if (Object.entries(data).length >= 25) this.state.CanLoad = true
            else this.state.CanLoad = false
            this.forceUpdate()
        })
    }

    DownloadFile(Name) {
        window.open(serverPath() + "api/files/download?File=" + Name, "_blank")
    }

    SortItems(Type) {
        switch (Type) {
            case 1:
                if (this.state.SortType[0] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.NumberInovice.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.NumberInovice.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[0] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.NumberInovice.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.NumberInovice.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                                return -1;
                            }
                            if (nameA < nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[0] = 1
                }
                this.forceUpdate()
                break
            case 2:
                if (this.state.SortType[1] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.NotionDate.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.NotionDate.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[1] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.NotionDate.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.NotionDate.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                                return -1;
                            }
                            if (nameA < nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[1] = 1
                }
                this.forceUpdate()
                break
            case 3:
                if (this.state.SortType[2] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  parseFloat(b.GrossAmountInovice.replaceAll(" ", "").replaceAll(",", ".")) - parseFloat(a.GrossAmountInovice.replaceAll(" ", "").replaceAll(",", "."))
                    )
                    this.state.SortType[2] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => parseFloat(a.GrossAmountInovice.replaceAll(" ", "").replaceAll(",", ".")) - parseFloat(b.GrossAmountInovice.replaceAll(" ", "").replaceAll(",", "."))
                    )
                    this.state.SortType[2] = 1
                }
                this.forceUpdate()
                break
            case 4:
                if (this.state.SortType[3] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.CurrencyInovice.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.CurrencyInovice.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[3] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.CurrencyInovice.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.CurrencyInovice.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                                return -1;
                            }
                            if (nameA < nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[3] = 1
                }
                this.forceUpdate()
                break
            case 5:
                if (this.state.SortType[4] == 1) {
                    this.state.Items.sort(
                        (a,b) =>  {
                            var nameA = a.Added.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Added.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[4] = 0
                } else {
                    this.state.Items.sort(
                        (a,b) => {
                            var nameA = a.Added.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Added.toUpperCase(); // ignore upper and lowercase
                            if (nameA > nameB) {
                                return -1;
                            }
                            if (nameA < nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        }
                    )
                    this.state.SortType[4] = 1
                }
                this.forceUpdate()
                break
        }
    }

    ShowMore(event, id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .classList.add("WorkViewNormal_selected")
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                }
            })
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".WorkViewNormal_selected_hide_bt")
            .forEach( e => {
                if (e.style.display === "none") {
                    e.style.display = "table-row"
                }
            })
    }

    Hide(event,id) {
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .classList.remove("WorkViewNormal_selected")
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".SendedDocuments_row_additionals")
            .forEach( e => {
                if (e.style.display === "table-row") {
                    e.style.display = "none"
                }
            })
        document.querySelector("#SendedDocuments_row_id_" + id.toString())
            .querySelectorAll(".WorkViewNormal_selected_hide_bt")
            .forEach( e => {
                if (e.style.display === "table-row") {
                    e.style.display = "none"
                }
            })
    }

    RenderData() {
        const tab = []

        for (let i = 0; i < this.state.Items.length; i++) {
            tab.push(
                <tr id={"SendedDocuments_row_id_" + i.toString()} className="">
                    <th style={{transition: "1s"}} colSpan={"6"}>
                        <table tyle={{transition: "5s"}} style={{width: "100%"}}>
                            <tbody>
                            <tr colSpan="6">
                                <th>
                                    <ScaleText style={{textAlign: "left"}} maxFontSize={16}>
                                        <a style={{textAlign: "left", paddingLeft: "11px", fontWeight: "normal", float: "left"}}>
                                            {
                                                this.state.Items[i].NumberInovice
                                            }
                                        </a>
                                    </ScaleText>

                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].NotionDate
                                            }
                                        </a>
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].GrossAmountInovice
                                            }
                                        </a>
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].CurrencyInovice
                                            }
                                        </a>
                                    </ScaleText>
                                </th>
                                <th>
                                    <ScaleText maxFontSize={16}>
                                        <a style={{fontWeight: "normal"}}>
                                            {
                                                this.state.Items[i].Added.split(" ")[0]
                                            }
                                        </a>
                                    </ScaleText>
                                </th>
                                <th onClick={e => this.ShowMore(e, i)} style={{width: '40px'}}>
                                    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_97_680)">
                                            <path d="M22.5 2.5H7.5C6.11929 2.5 5 3.61929 5 5V25C5 26.3807 6.11929 27.5 7.5 27.5H15.95C16.9442 27.4991 17.8974 27.1035 18.6 26.4L23.9 21.1C24.6035 20.3974 24.9991 19.4442 25 18.45V5C25 3.61929 23.8807 2.5 22.5 2.5ZM7.5 5H22.5V17.5H16.25C15.5596 17.5 15 18.0596 15 18.75V25H7.5V5Z"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_97_680">
                                                <rect width="30" height="30"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </th>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}} colSpan="6">
                                <td style={{paddingLeft: "11px"}} className="WorkViewNormal_selected_types" colSpan={"2"}>
                                    <b>Ścieżka dokumentu:</b>
                                    <a>
                                        {
                                            INVOICE_TYPE[this.state.Items[i].InvoiceType] !== undefined ? INVOICE_TYPE[this.state.Items[i].InvoiceType] : ""
                                        }
                                    </a>
                                </td>
                                <td  className="WorkViewNormal_selected_types" colSpan={"1"}>
                                    <div style={{
                                        position: 'relative',
                                        display: "inline-block",
                                        textAlign: "left",
                                        width: "100px",
                                        left: "50%",
                                        transform: "translate(-50%, 0)",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <b>Termin płatności:</b>
                                        <a>
                                            {
                                                this.state.Items[i].ExhibitionDate
                                            }
                                        </a>
                                    </div>

                                </td>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}}>
                                <th className="WorkViewNormal_selected_files" colSpan={"6"}>
                                    <b>Załączone dokumenty:</b>
                                    <div style={{paddingBottom: "20px"}} className="WorkViewNormal_selected_files_list">
                                        {
                                            (() => {
                                                const tab = []
                                                for(let d = 0; d < this.state.Items[i].FilesAdded.length; d++) {
                                                    tab.push(
                                                        <div>
                                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_95_565)">
                                                                    <path d="M16.6303 25.3C13.6766 28.24 8.89922 28.24 5.94544 25.3C3.01819 22.3718 3.01819 17.6282 5.94544 14.7L16.5678 4.14998C18.7874 1.94936 22.3681 1.94936 24.5877 4.14998C25.6514 5.19973 26.25 6.63126 26.25 8.12498C26.25 9.6187 25.6514 11.0502 24.5877 12.1L14.8412 21.775C14.1359 22.4888 13.1739 22.8905 12.17 22.8905C11.1661 22.8905 10.204 22.4888 9.49873 21.775C8.792 21.0714 8.39475 20.1155 8.39475 19.1187C8.39475 18.1219 8.792 17.1661 9.49873 16.4625L15.6795 10.3375C15.7969 10.2192 15.9568 10.1526 16.1236 10.1526C16.2904 10.1526 16.4503 10.2192 16.5678 10.3375L17.2434 11C17.3618 11.1173 17.4285 11.2771 17.4285 11.4437C17.4285 11.6104 17.3618 11.7701 17.2434 11.8875L11.0627 18.0125C10.5398 18.6396 10.5831 19.5619 11.1624 20.1374C11.7417 20.7128 12.665 20.7509 13.2897 20.225L23.0363 10.5625C23.685 9.91942 24.0498 9.04422 24.0498 8.13123C24.0498 7.21824 23.685 6.34304 23.0363 5.69998C21.6796 4.35248 19.4884 4.35248 18.1317 5.69998L7.48436 16.25C5.41644 18.3231 5.41644 21.6768 7.48436 23.75C9.58637 25.8098 12.9519 25.8098 15.0539 23.75L22.1229 16.7375C22.372 16.4896 22.7748 16.4896 23.0238 16.7375L23.6869 17.4C23.8079 17.5134 23.8765 17.6718 23.8765 17.8375C23.8765 18.0032 23.8079 18.1616 23.6869 18.275L16.6303 25.3Z" fill="white"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_95_565">
                                                                        <rect width="30" height="30" fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            <span style={{width: "80%", whiteSpace: "nowrap", textOverflow: "ellipsis", display: "block", overflow: "hidden", }} onClick={this.DownloadFile.bind(this, this.state.Items[i].FilesAdded[d].FilePath)}>
                                                                {
                                                                    this.state.Items[i].FilesAdded[d].Filename
                                                                }
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                return tab
                                            })()
                                        }
                                    </div>
                                </th>
                            </tr>
                            <tr className="SendedDocuments_row_additionals" style={{display: "none"}}>
                                <td colSpan={"6"}>
                                    <button style={{display: "none", position: "relative", float: "right", bottom: '0'}} onClick={e => this.Hide(e , i)} className="WorkViewNormal_selected_hide_bt">
                                        zwiń
                                        <svg width="27" height="38" viewBox="0 0 27 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.0574 37.5L12.1826 37.5C11.6648 37.5 11.2451 37.0803 11.2451 36.5625L11.2451 6.24375L2.92082 14.5875C2.7448 14.765 2.5052 14.8648 2.25525 14.8648C2.00529 14.8648 1.76569 14.765 1.58967 14.5875L0.27728 13.2562C0.0998148 13.0802 -1.0098e-05 12.8406 -1.00871e-05 12.5906C-1.00762e-05 12.3407 0.0998148 12.101 0.27728 11.925L11.7701 0.412499C12.0335 0.148701 12.391 0.000327613 12.7638 -5.89064e-07L13.4762 -5.57922e-07C13.8482 0.00431948 14.2042 0.152092 14.4699 0.4125L25.9627 11.925C26.1402 12.101 26.24 12.3407 26.24 12.5906C26.24 12.8406 26.1402 13.0802 25.9627 13.2562L24.6316 14.5875C24.4588 14.7639 24.2223 14.8633 23.9754 14.8633C23.7285 14.8633 23.492 14.7639 23.3192 14.5875L14.9948 6.24375L14.9948 21.4031L14.9948 36.5625C14.9948 37.0803 14.5751 37.5 14.0574 37.5Z" fill="white"/>
                                        </svg>
                                    </button>
                                    <ShowConfirmation data={{...this.state.Items[i], Name: this.props.UserData.Name, Surrname: this.props.UserData.Surrname}}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </th>
                </tr>
            )
        }

        if (tab.length == 0) {
            tab.push(<tr className="">
                <th colSpan={"5"}>
                    <h2 style={{textAlign: "center", marginTop: "20px"}}>Brak dokumentów</h2>
                </th>
            </tr>)
        }

        return tab
    }

    render() {
        return (
            <div style={{paddingBottom: "120px"}} className="WorkViewNormal">
                <div className="WorkViewNormal_header">
                    <h1>Wysłane dokumenty</h1>
                    <div className="DocumentSended_counters">
                        <a>{this.state.Counts.adds}</a>
                        <span style={{marginRight: "55px"}}>dokumentów ogółem</span>
                        <a>{this.state.Counts.last}</a>
                        <span>dokumentów w 30 dniach</span>
                    </div>
                    <InputSearch Filtres={[
                        {
                            Text: "Numer faktury",
                            ID: 1
                        },
                        {
                            Text: "Wartość",
                            ID: 2
                        },
                        {
                            Text: "Faktury kosztowe",
                            ID: 3
                        },
                        {
                            Text: "Faktury magazynowe",
                            ID: 4
                        }
                    ]} OnSearch={(q,f) => this.StartSearch(q,f)} className={"WorkViewNormal_header_search"} text={"Szukaj"}/>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th onClick={this.SortItems.bind(this,1)}>
                            <ScaleText maxFontSize={16}>
                                <a style={{textAlign: "left", paddingLeft: '30px'}}>Numer faktury
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </ScaleText>
                        </th>
                        <th onClick={this.SortItems.bind(this,2)}>
                            <ScaleText maxFontSize={16}>
                                <a>
                                    Data faktury
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </ScaleText>
                        </th>
                        <th onClick={this.SortItems.bind(this,3)}>
                            <ScaleText maxFontSize={16}>
                                <a>
                                    Wartość
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </ScaleText>
                        </th>
                        <th onClick={this.SortItems.bind(this,4)}>
                            <ScaleText maxFontSize={16}>
                                <a>
                                    Waluta
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </ScaleText>
                        </th>
                        <th onClick={this.SortItems.bind(this,5)}>
                            <ScaleText maxFontSize={16}>
                                <a>
                                    Data wysłania
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_95_457)">
                                            <path d="M7.15 8.06718C7.05535 7.97335 7.00211 7.84563 7.00211 7.71238C7.00211 7.57914 7.05535 7.45141 7.15 7.35758L11.28 3.21988C11.4205 3.07926 11.6112 3.00017 11.81 3H12.19C12.3884 3.0023 12.5783 3.08107 12.72 3.21988L16.85 7.35758C16.9447 7.45141 16.9979 7.57914 16.9979 7.71238C16.9979 7.84563 16.9447 7.97335 16.85 8.06718L16.14 8.77679C16.0478 8.8708 15.9217 8.92378 15.79 8.92378C15.6583 8.92378 15.5322 8.8708 15.44 8.77679L12 5.32871L8.56001 8.77679C8.46612 8.87139 8.33832 8.92461 8.205 8.92461C8.07169 8.92461 7.94389 8.87139 7.85 8.77679L7.15 8.06718ZM16.15 15.2132C16.0561 15.1186 15.9283 15.0654 15.795 15.0654C15.6617 15.0654 15.5339 15.1186 15.44 15.2132L12 18.6613L8.56001 15.2232C8.46784 15.1292 8.34169 15.0762 8.21 15.0762C8.07832 15.0762 7.95217 15.1292 7.86 15.2232L7.15 15.9328C7.05535 16.0266 7.00211 16.1544 7.00211 16.2876C7.00211 16.4209 7.05535 16.5486 7.15 16.6424L11.28 20.7801C11.4217 20.9189 11.6116 20.9977 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.7801L16.85 16.6424C16.9447 16.5486 16.9979 16.4209 16.9979 16.2876C16.9979 16.1544 16.9447 16.0266 16.85 15.9328L16.15 15.2132Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_95_457">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </ScaleText>
                        </th>
                        <th style={{width: "40px"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.RenderData()
                    }
                    </tbody>
                </table>
            </div>
        )
    }

}


export default SendedDocuments