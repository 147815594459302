import moment from "moment";
import React,{ Component } from "react";
import "../CSS/Normalnput.css"
import InputNormal from "./InputNormal";

class EndCalendar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Val: "",
            Anim: false,
            load: "",
            err: undefined,
            SelectMode: false,
            clicked: false,
            Calendar:false,
            StartDate: new Date(),
            SelectedDate: new Date(),
            YearMultipler: 0,
            MonthMultipler: 0,
        }
        this.state.err = this.props.err
        this.wrapper = React.createRef()
        this.cal_wrapper = React.createRef()
        this.input = React.createRef()
        this.HandleClick = this.HandleOutside.bind(this)
        this.MonthNames = {
            0: "STYCZEŃ",
            1: "LUTY",
            2: "MARZEC",
            3: "KWIECIEŃ",
            4: "MAJ",
            5: "CZERWIEC",
            6: "LIPIEC",
            7: "SIERPIEŃ",
            8: "WRZESIEŃ",
            9: "PAZDZIERNIK",
            10: "LISTOPAD",
            11: "GRUDZIEŃ",
        }
    }

    HandleOutside(event) {
        if (this.state.Calendar && this.wrapper && !this.wrapper.current.contains(event.target)   ) {
            // alert("Jest")
            this.state.Calendar = false
            this.state.MonthMultipler = 0
            this.state.YearMultipler = 0
            this.state.SelectMode = false
            this.forceUpdate()
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.HandleClick)
        if (this.input.current !== undefined && this.input.current !== null) {
            this.input.current.state.input.current.addEventListener("blur", (e) => this.ParseInput(e))
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mouseleave", this.HandleClick)
    }

    ShowCalendar(event) {
        if (!this.state.Calendar) {
            if (this.wrapper && this.wrapper.current.contains(event.target)) {
                this.state.SelectedDate = new Date(this.state.StartDate.getTime())
                this.state.Calendar = true
                this.forceUpdate()
            }

        }
    }

    NextMonth(direction) {
        if (direction == 1) {
            this.state.SelectedDate.setMonth(this.state.SelectedDate.getMonth() + 1)
            this.forceUpdate()
        } else {
            this.state.SelectedDate.setMonth(this.state.SelectedDate.getMonth() - 1)
            this.forceUpdate()
        }
    }

    ClickOnDate(date) {
        // alert(printStackTrace().join('\n\n'));
        this.setState(
            {
                ...this.state,
                StartDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                Val: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 ).toISOString().split("T")[0],
                Anim: true,
                err: undefined,
            },
            () => {
                this.input.current.state.input.current.value = this.state.Val
                this.input.current.TextChange(this.state.Val)
                if (this.props.onChange !== undefined) this.props.onChange(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                setTimeout(() => {
                    this.state.Calendar = false
                    this.forceUpdate()
                }, 50)
            }
        )

    }

    SaveSelector() {
        this.state.StartDate = new Date(
            this.state.SelectedDate.getFullYear() + this.state.YearMultipler,
            this.state.SelectedDate.getMonth() + this.state.MonthMultipler,
            1
        )
        this.state.SelectedDate = new Date(this.state.StartDate.getTime())
        this.state.SelectMode = false
        this.state.MonthMultipler = 0
        this.state.YearMultipler = 0
        this.state.Val = new Date(this.state.StartDate.getFullYear(), this.state.StartDate.getMonth(), this.state.StartDate.getDate() + 1 ).toISOString().split("T")[0]
        this.input.current.state.input.current.value = this.state.Val
        this.input.current.TextChange(this.state.Val)
        this.state.Anim = true
        this.state.err = undefined
        this.forceUpdate()
    }

    SelectYearMonthMode() {
        if (this.state.SelectMode) {
            this.state.SelectMode = false
        } else {
            this.state.SelectMode = true
        }
        this.forceUpdate()
    }

    ScrollSelector(event, type) {
        const ScrollDirection = (ev) => {
            if (ev.wheelDelta) {
                return ev.wheelDelta > 0;
            }
            return ev.deltaY < 0;
        }
        // console.log(ScrollDirection(event) ? "UP" : "DOWN")
        if (ScrollDirection(event)) {
            if (type === 1) this.state.YearMultipler -= 1
            else this.state.MonthMultipler -= 1
        } else {
            if (type === 1) this.state.YearMultipler += 1
            else this.state.MonthMultipler += 1
        }
        this.forceUpdate()
    }

    ClickSelector(type, multipler) {
        if (type === 1) {
            this.state.YearMultipler = multipler
        } else {
            this.state.MonthMultipler = multipler
        }
        this.forceUpdate()
    }

    CalendarPop() {
        return (
            <div style={{top: "80px"}} className="InputNormal_Box_calendar_box">
                {

                    (()=> {
                        if (!this.state.SelectMode)
                            return <div className="InputNormal_Box_calendar_box_header">
                                <b onClick={this.SelectYearMonthMode.bind(this)}>
                                    {this.MonthNames[this.state.SelectedDate.getMonth()]}
                                    <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_170_814)">
                                            <path d="M8.93756 10.084C8.81924 9.96669 8.75269 9.80703 8.75269 9.64048C8.75269 9.47392 8.81924 9.31426 8.93756 9.19697L14.1001 4.02485C14.2757 3.84908 14.514 3.75022 14.7626 3.75H15.2376C15.4856 3.75288 15.7229 3.85134 15.9001 4.02485L21.0626 9.19697C21.1809 9.31426 21.2474 9.47392 21.2474 9.64048C21.2474 9.80703 21.1809 9.96669 21.0626 10.084L20.1751 10.971C20.0599 11.0885 19.9022 11.1547 19.7376 11.1547C19.5729 11.1547 19.4153 11.0885 19.3001 10.971L15.0001 6.66088L10.7001 10.971C10.5827 11.0892 10.423 11.1558 10.2563 11.1558C10.0897 11.1558 9.92991 11.0892 9.81256 10.971L8.93756 10.084ZM20.1876 19.0165C20.0702 18.8983 19.9105 18.8317 19.7438 18.8317C19.5772 18.8317 19.4174 18.8983 19.3001 19.0165L15.0001 23.3266L10.7001 19.029C10.5849 18.9115 10.4272 18.8453 10.2626 18.8453C10.0979 18.8453 9.94026 18.9115 9.82506 19.029L8.93756 19.916C8.81924 20.0333 8.75269 20.193 8.75269 20.3595C8.75269 20.5261 8.81924 20.6857 8.93756 20.803L14.1001 25.9752C14.2772 26.1487 14.5145 26.2471 14.7626 26.25H15.2376C15.4861 26.2498 15.7244 26.1509 15.9001 25.9752L21.0626 20.803C21.1809 20.6857 21.2474 20.5261 21.2474 20.3595C21.2474 20.193 21.1809 20.0333 21.0626 19.916L20.1876 19.0165Z"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_170_814">
                                                <rect width="30" height="30"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </b>
                                <button style={{left: "5px"}} onClick={this.NextMonth.bind(this, 2)}>
                                    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.75 10.5L5.25 7L8.75 3.5" stroke="#1D3138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </button>
                                <button style={{right: "5px"}} onClick={this.NextMonth.bind(this, 1)}>
                                    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 10.5L8.75 7L5.25 3.5" stroke="#1D3138" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </button>
                                <div className="InputNormal_Box_calendar_box_header_names">
                                    <span>PN</span>
                                    <span>WT</span>
                                    <span>ŚR</span>
                                    <span>CZ</span>
                                    <span>PT</span>
                                    <span>SO</span>
                                    <span>N</span>
                                </div>
                            </div>
                        else return <div style={{height: "20px"}} className="InputNormal_Box_calendar_box_header">
                            {/* <button style={{left: "9px"}} onClick={this.SelectYearMonthMode.bind(this)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"/>
                                            </svg>
                                        </button> */}
                            <button style={{
                                position: "relative",
                                left: "50%",
                                transform: "translate(-50%, 0)",
                                color: "#23A287",
                                fontWeight: "600"
                            }} onClick={this.SaveSelector.bind(this)}>
                                {/* <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.36364 15.8209L1.59091 9.55224L0 11.6418L6.36364 20L20 2.08955L18.4091 0L6.36364 15.8209Z"/>
                                            </svg> */}
                                ZAPISZ
                            </button>
                        </div>
                    })()
                }
                {
                    (() => {
                        if (!this.state.SelectMode)
                            return <div className="InputNormal_Box_calendar_box_days">
                                {
                                    (() => {
                                        const tab = []
                                        let workdate = new Date(this.state.SelectedDate.getFullYear(),this.state.SelectedDate.getMonth() +1,0)
                                        for(let i = 1; i <= workdate.getDate(); i++) {
                                            let actualday = new Date(this.state.SelectedDate.getFullYear(), this.state.SelectedDate.getMonth(), i)
                                            if (i == 1) {
                                                let tempDate = new Date(workdate.getTime())
                                                tempDate.setDate(0)
                                                for(let d = 1; d < (actualday.getDay() == 0 ? 7 : actualday.getDay()   );d++) {

                                                    tempDate.setDate(tempDate.getDate() - ((actualday.getDay() == 0 ? 7 : actualday.getDay()) - 1)  + (d == 1 ? 1 : ((actualday.getDay() == 0 ? 7 : actualday.getDay()) - d) + d)  )
                                                    tab.push(
                                                        <span onClick={this.ClickOnDate.bind(this, new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate()))} style={{color: "#A4AAAA"}}>
                                                                    {
                                                                        tempDate.getDate()
                                                                    }
                                                                </span>)
                                                }
                                                // tab.push(
                                                //     <span style={{opacity: 0}}>
                                                //         0
                                                //     </span>)
                                                tab.push(
                                                    <span onClick={this.ClickOnDate.bind(this, new Date(actualday.getFullYear(), actualday.getMonth(), actualday.getDate()))} className={(i === this.state.StartDate.getDate()&&  actualday.getMonth() === this.state.StartDate.getMonth() && actualday.getFullYear() === this.state.StartDate.getFullYear()) && "InputNormal_Box_calendar_box_days_selected"}>
                                                                {i.toString()}
                                                        {
                                                            (
                                                                () => {
                                                                    if (i === this.state.StartDate.getDate()  &&  actualday.getMonth() === this.state.StartDate.getMonth() && actualday.getFullYear() === this.state.StartDate.getFullYear() )
                                                                        return <div style={{backgroundColor: "white", opacity: "1", zIndex: "-1"}} className="InputNormal_Box_calendar_box_header_week"></div>
                                                                    else if (actualday.getDay() == 0 || actualday.getDay() == 6)
                                                                        return <div className="InputNormal_Box_calendar_box_header_week"></div>
                                                                }
                                                            )()
                                                        }
                                                            </span>
                                                )
                                            } else tab.push(
                                                <span onClick={this.ClickOnDate.bind(this, new Date(actualday.getFullYear(), actualday.getMonth(), actualday.getDate() ))} className={(i === this.state.StartDate.getDate()  &&  actualday.getMonth() === this.state.StartDate.getMonth() && actualday.getFullYear() === this.state.StartDate.getFullYear() ) && "InputNormal_Box_calendar_box_days_selected"}>
                                                            {i.toString()}
                                                    {
                                                        (
                                                            () => {
                                                                if (i === this.state.StartDate.getDate()  &&  actualday.getMonth() === this.state.StartDate.getMonth() && actualday.getFullYear() === this.state.StartDate.getFullYear() )
                                                                    return <div style={{backgroundColor: "white", opacity: "1", zIndex: "-1"}} className="InputNormal_Box_calendar_box_header_week"></div>
                                                                else if (actualday.getDay() == 0 || actualday.getDay() == 6)
                                                                    return <div className="InputNormal_Box_calendar_box_header_week"></div>
                                                            }
                                                        )()
                                                    }
                                                        </span>
                                            )
                                        }
                                        return tab
                                    })()
                                }
                            </div>
                        else return <div style={
                            {
                                flexWrap: "nowrap"
                            }
                        } className="InputNormal_Box_calendar_box_days_v1">
                            <div onWheel={e => this.ScrollSelector(e, 1)} style={
                                {
                                    width: '80px',
                                    marginRight: ".2px"
                                }
                            } className="InputNormal_Box_calendar_box_selector">
                                {
                                    (
                                        () => {
                                            const tab = []
                                            let iterator = 0
                                            for(let c = -2 + this.state.YearMultipler; c <= 2 + this.state.YearMultipler; c++) {
                                                iterator += 1
                                                tab.push(
                                                    <a style={
                                                        (()=> {
                                                            if (iterator == 1 || iterator == 5)
                                                                return {opacity: ".5"}
                                                            else if (iterator == 2 || iterator == 4)
                                                                return {opacity: ".75"}
                                                        })()}
                                                       onClick={iterator !== 3 && this.ClickSelector.bind(this,1,c)} className={iterator === 3 && "InputNormal_Box_calendar_box_selector_selected"}>
                                                        {
                                                            new Date(
                                                                this.state.SelectedDate.getFullYear() + c,
                                                                this.state.SelectedDate.getMonth(),
                                                                1
                                                            ).getFullYear()
                                                        }
                                                    </a>
                                                )
                                            }
                                            return tab
                                        }
                                    )()
                                }
                            </div>
                            <div style={
                                {
                                    width: "130px"
                                }
                            } onWheel={e => this.ScrollSelector(e, 2)} className="InputNormal_Box_calendar_box_selector">
                                {
                                    (
                                        () => {
                                            const tab = []
                                            let iterator = 0
                                            for(let c = -2 + this.state.MonthMultipler; c <= 2 + this.state.MonthMultipler; c++) {
                                                iterator += 1
                                                tab.push(
                                                    <a style={
                                                        (()=> {
                                                            if (iterator == 1 || iterator == 5)
                                                                return {opacity: ".5"}
                                                            else if (iterator == 2 || iterator == 4)
                                                                return {opacity: ".75"}
                                                        })()
                                                    } onClick={iterator !== 3 && this.ClickSelector.bind(this,2,c)} className={iterator === 3 && "InputNormal_Box_calendar_box_selector_selected"}>
                                                        {
                                                            this.MonthNames[new Date(
                                                                this.state.SelectedDate.getFullYear(),
                                                                this.state.SelectedDate.getMonth() + c,
                                                                1
                                                            ).getMonth()]
                                                        }
                                                    </a>
                                                )
                                            }
                                            return tab
                                        }
                                    )()
                                }
                            </div>
                            <button style={{
                                position: "absolute",
                                left: "50%",
                                transform: "translate(-50%, 0)",
                                color: "#1D3138",
                                fontWeight: "600",
                                bottom: "-5px",
                                opacity: ".5",
                                backgroundColor: "transparent",
                                border: "none"

                            }} onClick={this.SelectYearMonthMode.bind(this)}>
                                {/* <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.36364 15.8209L1.59091 9.55224L0 11.6418L6.36364 20L20 2.08955L18.4091 0L6.36364 15.8209Z"/>
                                            </svg> */}
                                ZAMKNIJ
                            </button>
                        </div>
                    })()
                }


            </div>
        )
    }

    ParseInput(e) {
        this.state.Val = e.target.value

        let words = e.target.value.trim().split(" ").filter(entry => entry.trim() != '')
        let date = new Date()
        // alert(JSON.stringify(words))
        if (words.length == 2) {
            if (words[1].toLowerCase() == "dni") {
                try {
                    date.setDate(date.getDate() + parseInt(words[0]))
                } catch(err) {

                }
                // this.state.Val = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 ).toISOString().split("T")[0]


                // this.input.current.state.input.current.value = this.state.Val
                // this.input.current.TextChange(this.state.Val)

                this.ClickOnDate(date)
            } else {
                // this.state.Val = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 ).toISOString().split("T")[0]
                // this.input.current.state.input.current.value = this.state.Val
                // this.input.current.TextChange(this.state.Val)

                this.ClickOnDate(date)
            }
        } else {
            if (words.length !== 0)
            {
                let day = moment(words[0])
                if (day.isValid()) {
                    // alert(this.state.Val + " = "  + new Date(day.toDate().getFullYear(), day.toDate().getMonth(), day.toDate().getDate() + 1 ).toISOString().split("T")[0])
                    if (this.state.Val != new Date(day.toDate().getFullYear(), day.toDate().getMonth(), day.toDate().getDate() + 1 ).toISOString().split("T")[0]) this.ClickOnDate(day.toDate())
                }
                else this.ClickOnDate(date)
            }
            // this.input.current.state.input.current.value = this.state.Val
            // this.input.current.TextChange(this.state.Val)

        }

    }

    render() {
        return (
            // <div ref={this.wrapper} onClick={e => {this.ShowCalendar(e)}} style={this.props.style} className={"InputNormal_Box " + this.props.className }>
            //     {
            //         this.state.Val !== "" && <label className={this.state.Anim ? " InputNormal_Box_Active" : this.state.load}>{ this.state.err !== undefined ? this.state.err  : this.props.text}</label>
            //     }
            //     {
            //         (this.state.Val === "" && this.state.err != undefined) && <label className={"InputNormal_err_label"}>{this.state.err}</label>            
            //     }
            //     <a>{ this.state.Val === "" ? this.props.text : this.state.Val}</a>
            //     {
            //         this.state.Calendar && this.CalendarPop()
            //     }
            // </div>
            <div style={this.props.style} className={this.props.className }  ref={this.wrapper} onClick={e => {this.ShowCalendar(e)}} >
                <InputNormal ref={this.input} onClick={e => {this.ShowCalendar(e)}} className={"Huge_input"} style={{marginTop: "27px", width: '250px', display: "inline-block"}} text={"termin płatności"}/>
                {
                    this.state.Calendar && this.CalendarPop()
                }
            </div>

        )
    }

}

export default EndCalendar